'use strict';

angular.module('windmanagerApp')
  .directive('listingNav', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        jQuery('body').mobileNav({
          hideOnClickOutside: true,
          menuActiveClass: 'listing-active',
          menuOpener: '.btn-listing',
          menuDrop: '.listing-block'
        });
      }
    };
  });
